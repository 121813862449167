import { database } from "../config/firebase";

const initialState = {
  isLoading: null,
  error: null,
  data: []
};

// Actions
const REQUEST = "myDocuments/REQUEST";
const RECEIVE = "myDocuments/RECEIVE";
const FAIL = "myDocuments/FAIL";

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case RECEIVE:
      return {
        ...state,
        isLoading: false,
        data: action.data
      };
    case FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    default:
      return state;
  }
}

// Action Creaters
export function myDocumentsRequest() {
  return {
    type: REQUEST
  };
}

export function myDocumentsReceive(data) {
  return {
    type: RECEIVE,
    data
  };
}

export function myDocumentsFail(error) {
  return {
    type: FAIL,
    error
  };
}

export function getAllGeneratedFileNamesForCurrentUser() {
  return (dispatch, getState) => {
    dispatch(myDocumentsRequest());
    database
      .ref("currentlyGenerating")
      .once("value")
      .then(snapshot => {
        let generatingFiles = snapshot.val();
        const currentUserFileNames = [];
        generatingFiles = Object.keys(generatingFiles).forEach(function(key) {
          const { userId } = getState().auth;
          // Get only for current user by id
          if (generatingFiles[key].userId !== userId) return;

          // Get the ones with chosen title only
          if (generatingFiles[key].title === "") return;

          currentUserFileNames.push({
            title: generatingFiles[key].title,
            lastUpdated: generatingFiles[key].lastUpdated
          });
        });

        // Sort by date
        currentUserFileNames.sort((a, b) => {
          return new Date(b.lastUpdated) - new Date(a.lastUpdated);
        });

        dispatch(myDocumentsReceive(currentUserFileNames));
      })
      .catch(err => {
        dispatch(myDocumentsFail(err.message));
      });
  };
}
