import React from "react";
import { connect } from "react-redux";
import {
  Document,
  Page,
  StyleSheet,
  Text,
  Image,
  Font,
  View
} from "@react-pdf/renderer";
import benefitsHeader from "../assets/images/benefits-header.png";
import barriersHeader from "../assets/images/barriers-header.png";
import { loadPrincipleImageFromUrl } from "../helpers/common";
import { saveFile, saveFileRequest } from "../reducers/startedPdf";

Font.register(
  "https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf",
  { family: "Roboto" }
);
Font.registerHyphenationCallback(word => [word]);

const styles = StyleSheet.create({
  body: {},
  header: {
    display: "flex",
    justifyContent: "center",
    position: "relative"
  },
  imageHeader: {
    width: "100%",
    height: 120
  },
  title: {
    fontSize: 34,
    fontFamily: "Roboto",
    color: "white",
    textTransform: "uppercase",
    position: "absolute",
    paddingLeft: 45
  },
  section: {
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 45,
    paddingVertical: 10
  },
  name: {
    fontSize: 15,
    fontFamily: "Roboto",
    color: "#F79646",
    marginRight: 25,
    marginTop: 15,
    width: 145
  },
  description: {
    fontSize: 15,
    fontFamily: "Roboto",
    marginTop: 15,
    flex: 1
  },
  image: {
    width: 100,
    height: 100,
    marginRight: 10
  }
});

class MyDoc extends React.Component {
  saveGeneratedPdf = ({ blob }) => {
    const { isFileGenerating, data } = this.props.currentPdf;
    const isAlreadyGenerated = isFileGenerating === false;
    const isInProgress = isFileGenerating === true;

    if (isAlreadyGenerated || isInProgress) {
      return;
    }

    if (!blob) {
      return;
    }

    blob.name = `${data.title}.pdf`;
    this.props.saveFileRequest();
    this.props.saveFile(blob);
  };

  render() {
    const { data } = this.props;

    return (
      <Document title={`${data.title}.pdf`} onRender={this.saveGeneratedPdf}>
        {data.step1.options.length ? (
          <Page wrap>
            <View style={styles.header} fixed>
              <Image style={styles.imageHeader} src={benefitsHeader} />
              <Text style={styles.title} fixed>
                {data.step1.title || "common benefits"}
              </Text>
            </View>

            {data.step1.options.map(item => {
              return (
                <View style={styles.section} key={item.id}>
                  <Image
                    crossorigin="anonymous"
                    cache={false}
                    style={styles.image}
                    src={loadPrincipleImageFromUrl(item.image)}
                  />
                  <Text style={styles.name}>{item.name}</Text>
                  <Text style={styles.description}>{item.description}</Text>
                </View>
              );
            })}
          </Page>
        ) : null}

        {data.step2.options.length ? (
          <Page wrap>
            <View style={styles.header} fixed>
              <Image style={styles.imageHeader} src={barriersHeader} />
              <Text style={styles.title} fixed>
                {data.step2.title || "common barriers"}
              </Text>
            </View>

            {data.step2.options.map(item => {
              return (
                <View style={styles.section} key={item.id}>
                  <Image
                    crossorigin="anonymous"
                    cache={false}
                    style={styles.image}
                    src={loadPrincipleImageFromUrl(item.image)}
                  />
                  <Text style={styles.name}>{item.name}</Text>
                  <Text style={styles.description}>{item.description}</Text>
                </View>
              );
            })}
          </Page>
        ) : null}
      </Document>
    );
  }
}

const mapStateToProps = ({ currentPdf }) => ({
  currentPdf
});

const mapDispatchToProps = {
  saveFile,
  saveFileRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDoc);
