import React from "react";

export default Loader => (
  <div className="c-loader">
    <div className="loader">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);
