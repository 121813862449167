import React from "react";
import Principles from "../components/Principles";
import TitleComponent from "../components/TitleComponent";
import PDFGenerating from "../components/PDFGenerating";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1
    };
  }

  nextStep = () => {
    this.setState({
      currentStep: this.state.currentStep + 1
    });
  };

  previousStep = () => {
    this.setState({
      currentStep: this.state.currentStep - 1
    });
  };

  renderStep = () => {
    const step = this.state.currentStep;

    switch (step) {
      case 1:
        return (
          <Principles
            previousStep={this.previousStep}
            benefit
            nextStep={this.nextStep}
          />
        );
      case 2:
        return (
          <Principles
            previousStep={this.previousStep}
            barrier
            nextStep={this.nextStep}
          />
        );
      case 3:
        return (
          <TitleComponent
            previousStep={this.previousStep}
            nextStep={this.nextStep}
          />
        );
      case 4:
        return <PDFGenerating />;
      default:
        return (
          <Principles
            previousStep={this.previousStep}
            benefit
            nextStep={this.nextStep}
          />
        );
    }
  };

  render() {
    return <div className="home">{this.renderStep()}</div>;
  }
}

export default Home;
