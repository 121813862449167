import { database } from "../config/firebase";

const initialState = {
  data: [],
  isLoading: null,
  error: null
};

// Actions
const REQUEST = "principles/REQUEST";
const RECEIVE = "principles/RECEIVE";
const FAIL = "principles/FAIL";
const UPDATE_REQUEST = "principles/UPDATE_REQUEST";
const UPDATE_SUCCESS = "principles/UPDATE_SUCCESS";

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case RECEIVE:
      return {
        ...state,
        data: action.data,
        isLoading: false
      };
    case FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case UPDATE_SUCCESS:
      action.data.forEach(id => {
        delete state.data[id];
      });

      return {
        ...state,
        isLoading: false,
        data: state.data
      };
    default:
      return state;
  }
}

// Action Creaters
export function principlesRequest() {
  return {
    type: REQUEST
  };
}

export function principlesReceive(data) {
  return {
    type: RECEIVE,
    data
  };
}

export function principlesFail(error) {
  return {
    type: FAIL,
    error
  };
}

export function principlesUpdateRequest() {
  return {
    type: UPDATE_REQUEST
  };
}

export function principlesUpdate(data) {
  return {
    type: UPDATE_SUCCESS,
    data
  };
}

export function fetchAllPrinciples() {
  return dispatch => {
    dispatch(principlesRequest());
    database
      .ref("principles")
      .once("value")
      .then(snapshot => {
        const principles = snapshot.val();
        dispatch(principlesReceive(principles));
      })
      .catch(e => {
        dispatch(principlesFail(e.message));
      });
  };
}

/**
 * Delete already choosed principles
 * @param  {[string]} data
 * @return {[function]}
 */
export function updatePrinciples(data) {
  return dispatch => {
    dispatch(principlesUpdateRequest());
    dispatch(principlesUpdate(data));
  };
}
