export function loadPrincipleImageFromUrl(filename) {
  const encodeFilename = encodeURIComponent(filename);
  return `https://firebasestorage.googleapis.com/v0/b/${
    process.env.REACT_APP_STORAGE_BUCKET
  }/o/images%2F${encodeFilename}?alt=media`;
}

export function pdfDownloadLink(filename) {
  const encodeFilename = encodeURIComponent(filename);
  return `https://firebasestorage.googleapis.com/v0/b/${
    process.env.REACT_APP_STORAGE_BUCKET
  }/o/files%2F${encodeFilename}.pdf?alt=media`;
}
