import { database, storage } from "../config/firebase";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  isLoading: null,
  error: null,
  data: {
    userId: user ? user.uid : null,
    started: null,
    lastUpdated: null,
    step1: {
      options: []
    },
    step2: {
      options: []
    },
    title: ""
  },
  key: "",
  isFileGenerating: null
};

// Actions
const REQUEST_BENEFITS_SAVE = "benefits/REQUEST_SAVE";
const BENEFITS_SAVE = "benefits/SAVE";
const REQUEST_BARRIERS_SAVE = "barriers/REQUEST_SAVE";
const BARRIERS_SAVE = "barriers/SAVE";
const REQUEST_TITLE_SAVE = "title/REQUEST_SAVE";
const TITLE_SAVE = "title/SAVE";
const REQUEST_DATA_SAVE = "data/REQUEST_SAVE";
const DATA_SAVE = "data/SAVE";
const DATA_SAVE_FAIL = "data/SAVE_FAIL";
const REQUEST_FILE_SAVE = "file/REQUEST_SAVE";
const FILE_SAVE = "file/SAVE";
const FILE_SAVE_FAIL = "file/SAVE_FAIL";

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_BENEFITS_SAVE:
      return {
        ...state,
        isLoading: true
      };
    case BENEFITS_SAVE:
      const step1 = {
        options: action.data,
        title: action.title
      };
      return {
        ...state,
        data: {
          ...state.data,
          step1,
          lastUpdated: new Date().valueOf(),
          started: new Date().valueOf()
        },
        isLoading: false
      };
    case REQUEST_BARRIERS_SAVE:
      return {
        ...state,
        isLoading: true
      };
    case BARRIERS_SAVE:
      const step2 = {
        options: action.data,
        title: action.title
      };
      return {
        ...state,
        data: {
          ...state.data,
          step2,
          lastUpdated: new Date().valueOf()
        },
        isLoading: false
      };
    case REQUEST_TITLE_SAVE:
      return {
        ...state,
        isLoading: true
      };
    case TITLE_SAVE:
      return {
        ...state,
        data: {
          ...state.data,
          title: action.title,
          lastUpdated: new Date().valueOf()
        },
        isLoading: false
      };
    case REQUEST_DATA_SAVE:
      return {
        ...state,
        isLoading: true
      };
    case DATA_SAVE:
      return {
        ...state,
        isLoading: false,
        key: action.key
      };
    case DATA_SAVE_FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    case REQUEST_FILE_SAVE:
      return {
        ...state,
        isLoading: true,
        isFileGenerating: true
      };
    case FILE_SAVE:
      return {
        ...state,
        isLoading: false,
        isFileGenerating: false
      };
    case FILE_SAVE_FAIL:
      return {
        ...state
      };
    default:
      return state;
  }
}

// Action Creaters
export function benefitsSaveRequest() {
  return {
    type: REQUEST_BENEFITS_SAVE
  };
}

export function benefitsSaveSuccess(data, title) {
  return {
    type: BENEFITS_SAVE,
    data,
    title
  };
}

export function barriersSaveRequest() {
  return {
    type: REQUEST_BARRIERS_SAVE
  };
}

export function barriersSaveSuccess(data, title) {
  return {
    type: BARRIERS_SAVE,
    data,
    title
  };
}

export function titleSaveRequest() {
  return {
    type: REQUEST_TITLE_SAVE
  };
}

export function titleSaveSuccess(title) {
  return {
    type: TITLE_SAVE,
    title
  };
}

export function saveDataRequest() {
  return {
    type: REQUEST_DATA_SAVE
  };
}

export function saveDataSuccess(data) {
  return {
    type: DATA_SAVE,
    data
  };
}

export function saveDataError(error) {
  return {
    type: DATA_SAVE_FAIL,
    error
  };
}

export function saveFileRequest() {
  return {
    type: REQUEST_FILE_SAVE
  };
}

export function saveFileSuccess(data) {
  return {
    type: FILE_SAVE,
    data
  };
}

export function saveFileError(error) {
  return {
    type: FILE_SAVE_FAIL,
    error
  };
}

// Actions
export function saveBenefits(data, title) {
  return dispatch => {
    dispatch(benefitsSaveRequest());
    dispatch(benefitsSaveSuccess(data, title));
  };
}

export function saveBarriers(data, title) {
  return dispatch => {
    dispatch(barriersSaveRequest());
    dispatch(barriersSaveSuccess(data, title));
  };
}

export function saveTitle(title) {
  return dispatch => {
    dispatch(titleSaveRequest());
    dispatch(titleSaveSuccess(title));
  };
}

/**
 * Save the currently generating pdf data in firebase and save its key in redux.
 *
 * @return {function}
 */
export function saveData() {
  return (dispatch, getState) => {
    dispatch(saveDataRequest());
    const currentlyGenRef = database.ref("/currentlyGenerating");
    const currentlyGenRefPush = currentlyGenRef.push();
    const key = currentlyGenRefPush.key;
    const data = getState().currentPdf.data;
    currentlyGenRefPush
      .set(data)
      .then(snapshot => {
        dispatch(saveDataSuccess(key));
      })
      .catch(err => {
        dispatch(saveDataError(err.message));
      });
  };
}

export function saveFile(fileBlob) {
  return dispatch => {
    const imagesStorage = storage.ref();
    const imagesStorageChild = imagesStorage.child("files/" + fileBlob.name);
    imagesStorageChild
      .put(fileBlob)
      .then(function(snapshot) {
        dispatch(saveFileSuccess("data"));
      })
      .catch(err => {
        dispatch(saveFileError(err.message));
      });
  };
}
