import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import "./config/firebase";
import Home from "./Home";
import Login from "./Login";
import Documents from "./Documents";
import { getToken } from "./reducers/auth";
import Loader from "./components/Loader";

class App extends Component {
  async componentWillMount() {
    await this.props.getToken();
  }

  componentWillReceiveProps(nextProps) {
    const isNotAuthenticated =
      !nextProps.auth.isAuthenticated ||
      nextProps.auth.isAuthenticated === "null";
    const isOnHomePage = this.props.history.location.pathname === "/";
    if (isNotAuthenticated && isOnHomePage) {
      this.props.history.push("/login");
    }
  }

  render() {
    const { isAuthenticated, isLoading } = this.props.auth;

    if (isLoading || isLoading === null) {
      return <Loader />;
    }

    if (!isAuthenticated) {
      return (
        <div className="content">
          <Switch>
            <Route path="/login" component={Login} />
          </Switch>
        </div>
      );
    }

    return (
      <div className="content">
        <Switch>
          <Route path="/my-documents" component={Documents} />
          <Route path="/" component={Home} />
        </Switch>
      </div>
    );
  }
}
const mapStateToProps = ({ auth }) => ({
  auth
});

const mapDispatchToProps = {
  getToken
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
