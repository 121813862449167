import React from "react";
import { connect } from "react-redux";
import { Form, Icon, Input, Button } from "antd";
import { userLogIn } from "../reducers/auth";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Login extends React.Component {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userLogIn(values).then(res => {
          this.props.history.push("/");
        });
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;
    const { error } = this.props.auth;

    // Only show error after a field is touched.
    const emailHasError = isFieldTouched("email") && getFieldError("email");
    const passwordError =
      isFieldTouched("password") && getFieldError("password");
    return (
      <div className="c-form__wrapper">
        <h1> Benefits and Barriers Cheat Sheet </h1>
        <Form layout="inline" className="c-form" onSubmit={this.handleSubmit}>
          <Form.Item
            validateStatus={emailHasError ? "error" : ""}
            help={emailHasError || ""}
          >
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "The input is not valid E-mail!"
                },
                {
                  required: true,
                  message: "Please input your E-mail!"
                }
              ]
            })(
              <Input
                prefix={
                  <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Username"
              />
            )}
          </Form.Item>

          <Form.Item
            validateStatus={passwordError ? "error" : ""}
            help={passwordError || ""}
          >
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password!" }
              ]
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="c-form-button"
            disabled={hasErrors(getFieldsError())}
          >
            Log in
          </Button>
          {error && <p>{error}</p>}
        </Form>
      </div>
    );
  }
}

const WrappedHorizontalLoginForm = Form.create({ name: "normal_login" })(Login);

const mapStateToProps = ({ auth }) => ({
  auth
});

const mapDispatchToProps = {
  userLogIn
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedHorizontalLoginForm);
