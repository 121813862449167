import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "antd";
import DragnDrop from "./DragnDrop";
import Loader from "./Loader";
import { fetchAllPrinciples, updatePrinciples } from "../reducers/principles";
import { saveBenefits, saveBarriers } from "../reducers/startedPdf";
import logo from "../assets/images/logo.png";

class Principles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      benefitsTitle: this.props.benefitsTitle || "",
      barriersTitle: this.props.barriersTitle || ""
    };
  }

  async componentWillMount() {
    if (!this.props.data.length) {
      await this.props.fetchAllPrinciples();
    }
  }

  getAllSelectedItems = async items => {
    const itemIds = items.map(item => item.id);
    // Save only ids and remove them easily from store
    this.props.updatePrinciples(itemIds);
    // Check if items are benefit or barrier and save them in redux
    if (this.props.benefit) {
      this.props.saveBenefits(items, this.state.benefitsTitle);
    } else {
      this.props.saveBarriers(items, this.state.barriersTitle);
    }
  };

  previousStep = () => {
    this.child.setAllSelectedItems();
    this.props.previousStep();
  };

  saveAndContinue = () => {
    this.child.setAllSelectedItems();
    this.props.nextStep();
  };

  updateTitle = e => {
    if (this.props.benefit) {
      this.setState({
        benefitsTitle: e.target.value
      });
    } else {
      this.setState({
        barriersTitle: e.target.value
      });
    }
  };

  render() {
    const {
      isLoading,
      data,
      benefit,
      barrier,
      benefitsSelected,
      barriersSelected
    } = this.props;

    if (isLoading || isLoading === null) {
      return <Loader />;
    }

    return (
      <>
        <header
          className={`home__header home__header--${
            benefit ? "benefits" : "barriers"
          }`}
        >
          <div className="home__header-wrapper">
            <h1>Step {benefit ? 1 : 2}</h1>
            <div className="home__header-title">
              <img src={logo} width="100" height="100" alt="benefits logo" />
              <input
                className="home__header-input"
                placeholder={benefit ? "Common Benefits" : "Common Barriers"}
                onChange={this.updateTitle}
                value={
                  benefit ? this.state.benefitsTitle : this.state.barriersTitle
                }
              />
            </div>
            <Link to="/my-documents">My documents</Link>
          </div>
        </header>
        <div className="home__content">
          <p className="home__description">
            Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac odio
            mauris justo. Luctus arcu, urna praesent at id quisque ac. Arcu es
            massa vestibulum malesuada, integer vivamus elit eu mauris eus, cum
            eros quis aliquam wisi. Nulla wisi laoreet suspendisse integer
            vivamus elit eu mauris hendrerit facilisi, mi mattis pariatur
            aliquam pharetra eget.
          </p>
          {benefit ? (
            <DragnDrop
              items={data}
              ref={instance => {
                this.child = instance;
              }}
              getAllSelectedItems={this.getAllSelectedItems}
              selected={benefitsSelected || []}
            />
          ) : (
            <DragnDrop
              items={data}
              ref={instance => {
                this.child = instance;
              }}
              getAllSelectedItems={this.getAllSelectedItems}
              selected={barriersSelected || []}
            />
          )}
          <div className="home__buttons">
            {barrier && (
              <Button type="primary" size="large" onClick={this.previousStep}>
                Back
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              onClick={this.saveAndContinue}
              className="button-next"
            >
              Next
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ principles, currentPdf }) => {
  const principlesArray = [];
  const principlesData = principles && principles.data;
  const benefitsSelected =
    currentPdf && currentPdf.data.step1 && currentPdf.data.step1.options;
  const barriersSelected =
    currentPdf && currentPdf.data.step2 && currentPdf.data.step2.options;

  Object.keys(principlesData).forEach(function(key) {
    principlesArray.push({
      id: key,
      name: principlesData[key].name,
      image: principlesData[key].image,
      description: principlesData[key].description
    });
  });

  return {
    isLoading: principles.isLoading,
    error: principles.error,
    data: principlesArray,
    benefitsSelected,
    barriersSelected,
    benefitsTitle: currentPdf.data.step1.title,
    barriersTitle: currentPdf.data.step2.title
  };
};

const mapDispatchToProps = {
  fetchAllPrinciples,
  saveBenefits,
  saveBarriers,
  updatePrinciples
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Principles);
