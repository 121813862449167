import { auth } from "../config/firebase";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  isAuthenticated: false,
  isLoading: null,
  error: null,
  userId: user ? user.uid : null,
  token: user ? user.refreshToken : null,
  email: user ? user.email : null
};

// Actions
const REQUEST = "user/REQUEST";
const RECEIVE = "user/RECEIVE";
const FAIL = "user/FAIL";
const SET = "user/SET";
const REMOVE = "user/REMOVE";
const GET = "user/GET";

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case RECEIVE:
      return {
        ...state,
        userId: action.user.uid,
        token: action.user.refreshToken,
        email: action.user.email,
        isLoading: false,
        isAuthenticated: true,
        error: null
      };
    case SET:
      const userData = {
        uid: action.user.uid,
        email: action.user.email,
        refreshToken: action.user.refreshToken
      };
      localStorage.setItem("user", JSON.stringify(userData));
      return {
        ...state
      };
    case FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isAuthenticated: false
      };
    case REMOVE:
      localStorage.removeItem("user");
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        token: null
      };
    case GET:
      const user = JSON.parse(localStorage.getItem("user"));
      return {
        ...state,
        isAuthenticated: user && !!user.refreshToken,
        isLoading: false,
        token: user && user.refreshToken
      };
    default:
      return state;
  }
}

// Action Creaters
export function userRequest() {
  return {
    type: REQUEST
  };
}

export function userReceive(user) {
  return {
    type: RECEIVE,
    user
  };
}

export function userFail(error) {
  return {
    type: FAIL,
    error
  };
}

export function setLocalStorage(user) {
  return {
    type: SET,
    user
  };
}

export function getToken() {
  return {
    type: GET
  };
}

export function userLogIn(values) {
  return dispatch => {
    return new Promise(resolve => {
      dispatch(userRequest());
      auth
        .signInWithEmailAndPassword(values.email, values.password)
        .then(response => {
          resolve(response.user.uid);
          dispatch(userReceive(response.user));
          dispatch(setLocalStorage(response.user));
        })
        .catch(e => {
          dispatch(userFail(e.message));
        });
    });
  };
}
