import React from "react";
import { connect, Provider } from "react-redux";
import { Link } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import Document from "./PDFDocument";
import { saveFile, saveFileRequest } from "../reducers/startedPdf";
import store from "../index";

class PDFGenerating extends React.Component {
  render() {
    const { data } = this.props.currentPdf;

    return (
      <div className="home__document-wrapper">
        <div className="home__documents-link">
          See all of your generated documents
          <Link to="/my-documents"> here</Link>
        </div>
        <PDFViewer height="750" width="100%">
          {/* For some reason, after doing the internal magic */}
          {/* the inside react component can't access the store. */}
          {/* But store is needed, since we need to connect it */}
          {/* in order to use Redux store stuff. */}
          <Provider store={store}>
            <Document data={data} />
          </Provider>
        </PDFViewer>
      </div>
    );
  }
}

const mapStateToProps = ({ currentPdf }) => ({
  currentPdf
});

const mapDispatchToProps = {
  saveFile,
  saveFileRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PDFGenerating);
