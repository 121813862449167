import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Input } from "antd";
import Loader from "./Loader";
import { saveTitle, saveData } from "../reducers/startedPdf";

class TitleComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title || ""
    };
  }

  handleChange = e => {
    this.setState({
      title: e.target.value
    });
  };

  previousStep = () => {
    this.props.saveTitle(this.state.title);
    this.props.previousStep();
  };

  saveAndContinue = () => {
    this.props.saveTitle(this.state.title);
    this.props.saveData();
    this.props.nextStep();
  };

  render() {
    const { isLoading } = this.props;

    if (isLoading || isLoading === null) {
      return <Loader />;
    }

    return (
      <div className="home__preview">
        <div className="">
          <div className="home__preview-header">
            <h1>Step 3</h1>
            <Link to="/my-documents">My documents</Link>
          </div>
          <div className="home__preview-title-wrapper">
            <h3 className="home__preview-title">
              Choose a title for your document:
            </h3>
            <Input
              size="large"
              type="text"
              onChange={this.handleChange}
              value={this.state.title}
            />
          </div>
        </div>
        <div className="home__buttons">
          <Button type="primary" size="large" onClick={this.previousStep}>
            Back
          </Button>

          <Button
            type="primary"
            size="large"
            onClick={this.saveAndContinue}
            className="button-next"
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ currentPdf }) => {
  return {
    isLoading: currentPdf.isLoading,
    error: currentPdf.error,
    title: currentPdf.title
  };
};

const mapDispatchToProps = {
  saveTitle,
  saveData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TitleComponent);
