import React from "react";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import { getAllGeneratedFileNamesForCurrentUser } from "../reducers/finishedPdf";
import { pdfDownloadLink } from "../helpers/common";

class Documents extends React.Component {
  async componentWillMount() {
    await this.props.getAllGeneratedFileNamesForCurrentUser();
  }

  formatDate = _lastUpdated => {
    const date = new Date(_lastUpdated);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return date.toLocaleDateString("en-US", options);
  };

  render() {
    const { isLoading, data, error } = this.props.finishedPdf;

    if (isLoading || isLoading === "null") {
      return <Loader />;
    }

    if (error !== null) {
      return <p>{error}</p>;
    }

    return (
      <div className="document">
        <h1 className="document__title">All of your generated files</h1>
        <div className="document__wrapper">
          {!data.length && <p>You have no generated files yet!</p>}
          {data.map(el => (
            <a
              key={el.lastUpdated}
              className="document__box"
              href={pdfDownloadLink(el.title)}
            >
              <p>{`${el.title}.pdf`}</p>
              <p className="document__box-title">
                {this.formatDate(el.lastUpdated)}
              </p>
            </a>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ finishedPdf }) => ({
  finishedPdf
});

const mapDispatchToProps = {
  getAllGeneratedFileNamesForCurrentUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Documents);
