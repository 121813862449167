import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import authReducer from "./reducers/auth";
import principlesReducer from "./reducers/principles";
import currentPdfReducer from "./reducers/startedPdf";
import finishedPdfReducer from "./reducers/finishedPdf";

import "./assets/sass/main.scss";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [];
middlewares.push(thunk);

const reducer = combineReducers({
  auth: authReducer,
  principles: principlesReducer,
  currentPdf: currentPdfReducer,
  finishedPdf: finishedPdfReducer
});

let store;
// disable redux-logger and redux dev tools in production mode
if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
  store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(...middlewares))
  );
} else {
  store = createStore(reducer, applyMiddleware(...middlewares));
}

export default store;

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <Route path="/" component={App} />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
